import instance from './index';
import { useQuery, useMutation } from 'react-query';


//Access Controls Hospital API
const getHospitalAccessControls = async (hospitalId) => await instance.get(`/accessControl/hospital/${hospitalId}`);

const getHospitalAccessControlLists = async (hospitalId) => await instance.get(`/accessControlList/hospital/${hospitalId}`);

const getRoleAccessControlLists = async (roleId) => await instance.get(`/accessControlList/accessControl/${roleId}`);

const getMenuList = async (hospitalId) => await instance.get(`/mainMenu/menulist/${hospitalId}`);

const addAccessControl = async (payload) => await instance.post('/accessControl', payload);

const addCompleteAccessControl = async (payload) => await instance.post('/accessControl/complete', payload);

const updateAccessControl = async ({payload, accessControlId}) => await instance.put(`/accessControl/${accessControlId}`, payload);

const getAccessControlTimes = async (roleId) => await instance.get(`/roleAccessTime/role/${roleId}`);

const addAccessControlTimes = async ({payload}) => await instance.post('/roleAccessTime', payload);

const addAccessBulkControlTimes = async ({payload}) => await instance.post('/roleAccessTime/bulk', payload);

//Access Controls Queries
export function useGetMenuList(hospitalId) {
    return useQuery(['getMenuList'], () => getMenuList(hospitalId));
}

export function useGetHospitalAccessControls(hospitalId) {
    return useQuery(['getHospitalAccessControls'], () => getHospitalAccessControls(hospitalId));
}

export function useGetHospitalAccessControlLists(hospitalId) {
    return useQuery(['getHospitalAccessControlLists'], () => getHospitalAccessControlLists(hospitalId));
}

export function useGetRoleAccessControlLists(roleId) {
    return useQuery({
        queryKey:['getRoleAccessControlLists'], 
        queryFn:() => getRoleAccessControlLists(roleId), 
        // enabled:!!roleId,
        cacheTime: 0});
}

export function useAddAccessControl() {
    return useMutation(addAccessControl);
}

export function useAddCompleteAccessControl(){
    return useMutation(addCompleteAccessControl);
}

export function useUpdateAccessControl() {
    return useMutation(updateAccessControl);
}

export function useGetAccessControlTimes(roleId){
    return useQuery(['getAccessControlTimes'], () => getAccessControlTimes(roleId));
}

export function useAddAccessControlTimes(){
    return useMutation(addAccessControlTimes);
}

export function useAddAccessBulkControlTimes(){
    return useMutation(addAccessBulkControlTimes);
}
