import axios from 'axios';

const instance = axios.create({
    //   baseURL: 'http://48.216.248.163:9100/authref/v1.0',
    baseURL: 'https://api.synergyhms.com/authref/v1.0', 
    //   baseURL: 'http://localhost:9100/authref/v1.0',
    // baseURL: 'http://localhost:8010/proxy/authref/v1.0',
    headers: {
        'Content-Type': 'application/json',
    }
});

instance.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem('admin-token');
        const hospital = JSON.parse(sessionStorage?.getItem('hmsv2-hospital')) || null;

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        config.headers.dbname = hospital?.dbName || '';

        return config;
    }, error => {
        return Promise.reject(error);
    }
);

export default instance;
