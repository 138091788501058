/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { loginlogo } from '../../imagepath';
import { Link } from 'react-router-dom';
import { useSelectedUser } from '../../staff/SelectedUserProvider';
import { Select, notification } from 'antd';
import { useGetCompleteMenu, useGetHospitals } from '../../service/onboarding.service';
import { useBranchesByHospital } from '../../service/branch.service';
import _ from 'lodash';

const LockScreen = () => {

    const {
        hospital,
        setHospital,
        setMenuList,
        loginRole,
        loginUser,
        handleLogoutUser,
        assignedBranches,
        setLoginBranch
    } = useSelectedUser();

    const [curBranch, setCurBranch] = useState(0);
    const [hospitalId, setHospitalId] = useState(null);
    const [branches, setBranches] = useState([]);

    const { data: hospitals, isError, error, isLoading } = useGetHospitals();
    const { data: branchData, isFetched } = useBranchesByHospital(hospitalId);
    const { data: menus, isFetched: menuFetched } = useGetCompleteMenu(hospitalId);

    useEffect(() => {

        if (isFetched && loginRole?.roleType === 'SUPER_ADMIN') {
            setBranches(branchData?.data || []);
        }


        if (menuFetched && loginRole?.roleType === 'SUPER_ADMIN') {
          
            sessionStorage.setItem('hmsv2-mainMenu', JSON.stringify(_.groupBy(menus.data?.mainMenu, 'orderId')));
            sessionStorage.setItem('hmsv2-menu', JSON.stringify(menus.data?.menus));
            sessionStorage.setItem('hmsv2-submenu', JSON.stringify(menus.data?.submenus));

            setMenuList({
                'mainMenu': _.groupBy(menus.data?.mainMenu, 'orderId'),
                'menus': menus.data?.menus,
                'submenus': menus.data?.submenus
            });
        }

    }, [isFetched, menuFetched]);

    const submitBranch = () => {

        let acurbranch = (loginRole?.roleType === 'SUPER_ADMIN') ?
            branches.filter(branch => branch.branchId === curBranch) :
            assignedBranches.filter(branch => branch.branchId === curBranch);

        if (loginRole?.roleType === 'SUPER_ADMIN') {
            let acurhospital = hospitals.data.filter(hospital => hospital.hospitalId === hospitalId);

            sessionStorage.setItem('hmsv2-hospital', JSON.stringify(acurhospital[0]));
            setHospital(acurhospital[0]);
        }

        if (acurbranch.length > 0) {

            sessionStorage.setItem('hmsv2-branch', JSON.stringify(acurbranch[0]));
            setLoginBranch(acurbranch[0]);

            window.location.reload();
        }
    }

    if(isError){
        
        notification.warning({'message':'You cannot login to the system at this time, please consult your Admin...'})
    }

    // if (isLoading) {
    //     return <div>Loading...</div>
    // }

    return (
        <div className="main-wrapper login-body">
            <div className="container-fluid px-0">
                <div className="row">
                    <div style={{ width: '30rem', margin: '0 auto', top: '50%', left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)' }}>
                        <div className="login-wrapper">
                            <div className="loginbox">
                                <div className="login-right">
                                    <div className="login-right-wrap">
                                        <div className="account-logo">
                                            <Link to="/admin-dashboard">
                                                <img src={loginlogo} alt="" />
                                            </Link>
                                        </div>
                                        <div className="user-lock-screen">
                                            <h2>Hi, {loginUser.firstName}</h2>
                                            <p style={{ color: '#00D3C7' }}>Select {loginRole?.roleType === 'SUPER_ADMIN' && `a hospital and`} a branch to start working!</p>
                                        </div>
                                        {/* Form */}
                                        <form action="login.html">
                                            {loginRole?.roleType !== 'SUPER_ADMIN' &&
                                                <p className='mb-4 text-center' style={{ fontWeight: 'bold', fontSize: '25px', color: '#2E37A4' }}>
                                                    {hospital?.name}
                                                </p>}
                                            {loginRole?.roleType !== 'SUPER_ADMIN' && <div className="form-group">
                                                <label style={{ zIndex: 2000 }}>Assigned Branch(es) </label>
                                                <Select className="form-control" placeholder="Select Branch"
                                                    variant="borderless" onChange={(value) => setCurBranch(value)}>
                                                    {assignedBranches !== null && assignedBranches.map((branch, idx) => (
                                                        <Select.Option key={idx} value={branch.branchId}>{branch.name}</Select.Option>))}
                                                </Select>
                                                <span className="profile-views feather-eye-off toggle-password" />
                                            </div>}

                                            {loginRole?.roleType === 'SUPER_ADMIN' && <div className="form-group">
                                                <label style={{ zIndex: 2000 }}>Hospital </label>
                                                <Select className="form-control" placeholder="Select Branch" value={hospitalId}
                                                    variant="borderless" onChange={(value) => setHospitalId(value)}>
                                                    {(hospitals?.data || []).map((hospital, idx) => (
                                                        <Select.Option key={idx} value={hospital.hospitalId}>{hospital.name}</Select.Option>))}
                                                </Select>
                                                <span className="profile-views feather-eye-off toggle-password" />
                                            </div>}
                                            {loginRole?.roleType === 'SUPER_ADMIN' && <div className="form-group">
                                                <label style={{ zIndex: 2000 }}>Branches </label>
                                                <Select className="form-control" placeholder="Select Branch"
                                                    variant="borderless" onChange={(value) => setCurBranch(value)}>
                                                    {branches.map((branch, idx) => (
                                                        <Select.Option key={idx} value={branch.branchId}>{branch.name}</Select.Option>))}
                                                </Select>
                                                <span className="profile-views feather-eye-off toggle-password" />
                                            </div>}

                                            <div className="form-group login-btn">
                                                <button onClick={submitBranch} className="btn btn-primary btn-block" type="submit">
                                                    Continue to branch
                                                </button>
                                            </div>
                                        </form>
                                        {/* /Form */}
                                        <div className="next-sign">
                                            <p className="account-subtitle">
                                                Sign in as a different user?{" "}
                                                <Link to="/#" onClick={handleLogoutUser}>Login</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /Login Content */}
                </div>
            </div>
        </div>
    )
}

export default LockScreen;
